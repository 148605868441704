import React from 'react'
import Layout from 'components/layout'
import { Helmet } from 'react-helmet'
import HeroGradient from 'components/hero-image/hero-image-gradient'
import Promos from 'components/promos'
import Floater from 'components/floating-grid'
import { graphql } from 'gatsby'
import Content from 'components/category/content'

const items = [
  {
    link: '/stihl/wet-dry-vacuums/homeowner-vacuums/',
    imageId: 'homeownerVaccumsImage',
    name: 'Homeowner Wet/Dry Vacuums',
    alt: 'STIHL Homeowner Wet/Dry Vacuums',
  },
  {
    link: '/stihl/wet-dry-vacuums/professional-vacuums/',
    imageId: 'professionalVaccumsImage',
    name: 'Professional Wet/Dry Vacuums',
    alt: 'STIHL Professional Wet/Dry Vacuums',
  },
]

const WetDryVaccumsPage = ({ data }) => {
  const { allHutsonPromotion, heroImage } = data
  const promos = []
  if (allHutsonPromotion) {
    allHutsonPromotion.nodes.forEach(node => promos.push(node))
  }
  const floaterItems = items.map(item => {
    item.image = data[item.imageId]
    return item
  })
  return (
    <Layout>
      <Helmet>
        <title>STIHL Wet/Dry Vacuums | Hutson Inc</title>
        <meta
          name='description'
          content='Tackle all your tough cleaning tasks with a powerful STIHL wet/dry vacuum, perfect for use around the house or the workshop.'
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'STIHL',
                'item': 'https://www.hutsoninc.com/stihl/',
              },
              {
                '@type': 'ListItem',
                'position': 3,
                'name': 'Wet/Dry Vacuums',
                'item': 'https://www.hutsoninc.com/stihl/wet-dry-vacuums/',
              },
            ],
          })}
        </script>
      </Helmet>

      <HeroGradient image={heroImage} heading='STIHL Wet/Dry Vacuums' />

      <Content>
        <Floater items={floaterItems} />

        <Promos data={promos} type='STIHL Wet/Dry Vaccum' />
      </Content>
    </Layout>
  )
}

export const pageQuery = graphql`
  query wetDryVacuumsStihlQuery($category: String = "wet-dry-vacuums") {
    allHutsonPromotion(
      filter: {
        categoryList: { elemMatch: { category: { eq: $category }, type: { eq: "stihl" } } }
      }
      sort: { fields: [endDate], order: DESC }
      limit: 3
    ) {
      nodes {
        ...HutsonPromo
      }
    }
    heroImage: file(relativePath: { eq: "stihl/wet-dry-vacuums/wet-dry-vacuums-bg.jpg" }) {
      ...FullWidthImage
    }
    homeownerVaccumsImage: file(
      relativePath: { eq: "stihl/wet-dry-vacuums/homeowner-vacuum.jpg" }
    ) {
      ...FloatingGridImage
    }
    professionalVaccumsImage: file(
      relativePath: { eq: "stihl/wet-dry-vacuums/professional-vacuum.jpg" }
    ) {
      ...FloatingGridImage
    }
  }
`

export default WetDryVaccumsPage
